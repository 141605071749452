// Media
// Base selectors for images, video, audio...
/*
img, video {
    max-width: 100%;
    width: 100%;
    vertical-align: top;
}
*/

img.lazyload,
img.lazyloading {
	opacity: 0;
}

img.lazyload-always-visible {
	opacity:1;
}

img.lazyloaded {
	opacity: 1;
	@include transition(opacity, $speed, $ease);
}

// img[data-sizes="auto"] { display: block; width: 100%; }
