// .o-video iframe {
//   //width: auto;
//   //max-width: none;
//   //height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   cursor: pointer;
//   .player {
//     cursor: pointer !important;
//   }
// }

body:not(.is-touch) {
  .o-video iframe {
    pointer-events: none;
  }
}

.o-video__buttons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display:block;
}

.o-video__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display:none;
  span {
    position: absolute;
    left:50%;
    top:50%;
    @include transform(translate(-50%,-50%));
    color: #ffffff;
    text-align: center;
  }
}

.o-video__button.play {
  display:block;
}
.o-video__button.pause {
  display:none;
}

.o-video.is-playing {
  .o-video__button.play {
    display:none;
  }
  .o-video__button.pause {
    display:block;
  }
}

.o-video .o-video__buttons{
  display:none;
}


body.is-touch {
  .o-video .o-video__buttons{
    display:block;
  }
  .o-video.is-playing {
    .o-video__button.play {
      display:none;
    }
    .o-video__button.pause {
      display:block;
      opacity: 0;
    }
  }

}

.hidden {
  display:none;
}
