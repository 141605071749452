.c-article {
  @include  parent-behaviour();
  position: relative;
  margin-top: 16.5em;
  margin-bottom:  $padding*2;
}

.c-article__meta {
  margin-top: $padding;
  margin-bottom: $padding;
}

.c-article__bg-image {
  position:fixed;
  top:50%;
  left:50%;
  max-width:50%;
  @include transform(translate(-50%,-50%));
}

.c-article__images {
  padding: $padding 0;
}

.c-article__image {
  padding: $padding 0;
  img {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
