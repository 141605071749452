.mWrap {opacity:0;}
.mMove {opacity:0; white-space:nowrap; transform: translateZ(0);}
.mWrap.mIni,
.mWrap.mIni .mMove {opacity:1}
.mWrap,
.mWrap *{
	-moz-user-select: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	user-select: none;
}
.grab {
	cursor: -moz-grab;
	cursor: -webkit-grab;
	cursor: grab;
}
.grabbing {
	cursor: -moz-grabbing !important;
	cursor: -webkit-grabbing !important;
	cursor: grabbing !important;
}
.strTools *,
.strTools *:after,
.strTools *:before {
	box-sizing:border-box !important;
	-moz-user-select: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	user-select: none;
}
.mWrap img {
	// max-width:none !important;
}
.str_move {
	cursor:move;
}
.str_static .str_move,
.no_drag .str_move,
.noStop .str_move{
	cursor:inherit;
}


/* === Appearance Marquee === */
.mWrap {
	width:100%;
	font-size:inherit;
	line-height:inherit;
	font-family:inherit;
	text-transform:none;
	text-align:left;
	background:none;
	color:inherit;
}
