.c-collection,
.c-collection-list-container,
.c-collection-list {
  ::-webkit-scrollbar {
      width: 0px;  /* remove scrollbar space */
      background: transparent;  /* optional: just make scrollbar invisible */
  }
}

.c-collection {
  overflow: hidden;
  padding-top: 1em;
  padding-bottom: 1em;
}

.c-collection-list-container {
  overflow-x:scroll;
  width:100%;
  margin-left: $padding;
}

.c-collection-list {
  white-space:nowrap;
  list-style: none;
  //@include clear();
}

.c-collection-listitem {
  display: inline-block;
  vertical-align:top;
  white-space:normal;
}

.c-collection-title {
  @include font('medium');
  display: block;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.c-collection-title__title {
  float:left;
  text-align: left;
  width:90%;
  // @include media-query(med-sm){
  //   width:100%;
  // }
}

.c-collection-title:hover {
  span.open {
    display: none;
  }
  span.close {
    display: none;
  }
  span.hoverstate {
    display: inline-block;
  }
}

.c-collection-title.is-toggled {
  .c-collection-title__indicator {
    opacity: 1;
    span.open {
      display: none;
    }
    span.close {
      display: inline-block;
    }
    span.hoverstate {
      display: none;
    }
  }
}


.c-collection-title__indicator {
  opacity: 1;
  span.open {
    display: inline-block;
  }
  span.close {
    display: none;
  }
  span.hoverstate {
    display: none;
  }
}


.c-collection-title__indicator {
  position: absolute;
  right:0;
}
