// Color

/* =========================================================================  */
/* Color */
/* =========================================================================  */

$black: #000000;
$fake-black: #1d1d1d;
$white: #ffffff;
$fake-white: #fcfcfc;

// From dark to light
$gray: rgb(235, 235, 235);
$gray-1: rgb(175, 175, 175);
$gray-2: rgb(205, 205, 205);
$gray-3: rgb(235, 235, 235);

/* =========================================================================  */
/* Aliases */
/* =========================================================================  */

$background-color: $white;
$primary-color: $black;
$text-color: $black;
$text-gray: $gray-1;
$border-color: $gray-2;
