.c-site-main {
	@include parent-behaviour();

	position: relative;
	opacity: 0.001;
	min-height: 100vh;
  /* hack to engage viewport-units-buggyfill */
  // content: 'viewport-units-buggyfill; min-height: 100vh;';

	@include transition(opacity, $slower, $ease);

	&.is-active {
		opacity: 1;
	}

}
