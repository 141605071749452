.o-cursor {
  position: absolute;
  top:-9999px;
  pointer-events: none;
  user-select: none;
  white-space: nowrap;
  @include transform(translate(-50%,-50%));
}

.o-cursor--playpause {
  span.play {
    display:block;
  }
  span.pause {
    display:none;
  }
}


.o-cursor--playpause.has-pause-message {
  span.play {
    display:none;
  }
  span.pause {
    display:block;
  }
}
