.o-button {
    @include button-reset;
}

body.page-is-project,
body.page-is-article {
  .o-button--toggle-header {
    display:none;
  }
}

.o-button--toggle-header {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  color: inherit;
  z-index: $z-header;
  padding: $padding;

  span {
    display:block;
    background-color: $text-color;
    width:2em;
    height:2em;
    border-radius:50%;
    color: inherit;
    @include transition(all, $speed, $ease);
  }

  opacity: 1;
  user-select: none;

  @include transition(all, $speed, $ease);

  &.is-hidden{
   opacity: 0;
  }


  @include media-query(med-sm) {
    display: none;

  }

}

.o-button--toggle__indicator {
  span:first-child {
    display: block;
  }
  span:last-child {
    display: none;
  }
}

.o-button--toggle__text {
  span:first-child {
    display: inline;
  }
  span:last-child {
    display: none;
  }
}

.o-button--toggle.is-toggled {
  .o-button--toggle__indicator {
    opacity: 1;
    span:first-child {
      display: none;
    }
    span:last-child {
      display: block;
    }
  }
  .o-button--toggle__text {
    opacity: 1;
    span:first-child {
      display: none;
    }
    span:last-child {
      display: inline;
    }
  }
  .o-button--toggle__title {
		@include transform(translateX(0.75em));
	}
}

.o-button--toggle {
	cursor: pointer;

	&:hover,
	&:focus{
		.o-button--toggle__indicator {
			opacity: 1;
		}
		.o-button--toggle__title {
			@include transform(translateX(0.75em));
		}
	}

}

.o-button--toggle__title {
	display:inline-block;
	@include transition(transform, $speed, $ease);
}

.o-button--toggle {
  .o-button--toggle__indicator {
  	position:absolute;
  	left:0;
  	opacity: 0;
  	@include transition(opacity, $speed, $ease);
  }
}
