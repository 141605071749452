//Utility classes for visibility states

.u-hidden {
  display: none !important;
}

.u-visible {
  display: block !important;
}

.u-visible-inline {
  display: inline !important;
}

.u-visible-inline-block {
  display: inline-block !important;
}

.u-visible-flex {
  display: flex !important;
}

.u-visible-inline-flex {
  display: inline-flex !important;
}

.u-visible-min-br-small {
	display: none !important;
	@include mq-min-w(small) {
		display: block !important;
	}
}

.u-visible-min-br-medium {
	display: none !important;
	@include mq-min-w(med) {
		display: block !important;
	}
}

.u-visible-min-br-med-small {
	display: none !important;
	@include mq-min-w(med-sm) {
		display: block !important;
	}
}

.u-visible-min-br-large {
	display: none !important;
	@include mq-min-w(large) {
		display: block !important;
	}
}

.u-hidden-max-br-small {
  @include media-query(sm) {
		display: none !important;
	}
}

.u-hidden-max-br-medium {
	@include media-query(med) {
		display: none !important;
	}
}

.u-hidden-max-br-med-small {
  @include media-query(med-sm) {
		display: none !important;
	}
}

.u-hidden-max-br-large {
  @include media-query(large) {
		display: none !important;
	}
}

.u-has-child-hoverstate {
  display: inline-block;
  .u-visible-child-on-hover {
    display: none;
  }
  .u-hidden-child-on-hover {
    display: inline-block;
  }
  &:hover {
    .u-visible-child-on-hover {
      display: inline-block;
    }
    .u-hidden-child-on-hover {
      display: none;
    }
  }
}

.u-has-child-hoverstate--fade {
  .u-visible-child-on-hover--fade {
    opacity:0;
  }
  .u-hidden-child-on-hover--fade {
    opacity:1;
  }
  &:hover {
    .u-visible-child-on-hover--fade {
      opacity:1;
    }
    .u-hidden-child-on-hover--fade {
      opacity:0;
    }
  }
}

.u-has-animation--fade {
  @include transition(opacity, $speed, $ease);
}
