.c-contact,
.c-error {
	z-index: $z-header;
	position:fixed;
	padding-left: $padding;
	left: 0;
	bottom: 0;
	padding-bottom:  $padding*1.25;
	opacity: 1;
	@include transition(opacity, $speed, $ease);
	&.is-hidden{
	 opacity: 0;
	}

	@include media-query(sm) {
		padding-bottom: $padding*1.35;
	}
}

.c-contact-background,
.c-error-background {
	position: absolute;
	width:100%;
	height:100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
