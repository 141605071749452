//Base

html {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}

html, body {
	width: 100%;
	background-color: $background-color;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

*, *::before, *::after {
	box-sizing: inherit;
}

body {
	overflow-x: hidden;
	@include transition(all, $slower, $ease);
}
body.no-scroll {
	overflow: hidden;
}

body.page-is-inactive {
	.c-site-header {
		opacity: 0;
	}
}

html.no-js {

  .l-container,
  .l-container .c-site-main {
    opacity: 1 !important;
  }

  .o-item__loader,
  .o-image__loader {
  	display: none !important;
  }

  .o-image,
  .o-image img {
    opacity: 1 !important;
  }

  img.lazyload {
        display: none !important;
  }

}

body.page-transition-helper {
	.page-wrapper {
		@include transform(translate3d(0,0,0));
		@include transition(transform, $normal, $ease);
	}
}

body.page-is-pushed {
	.page-wrapper {
		@include transform(translate3d(0,27.5vw,0));
	}
}

body.no-scroll {
	overflow: hidden;
}

body.page-is-inactive {
	.c-site-header {
		opacity: 0;
	}
}
