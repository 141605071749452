.c-blog {
  margin-top: 16.5em;
  @include media-query(med-sm) {
    margin-top: -0.5em;
  }
}

.c-blog-post {
  margin-bottom: $padding;
  &:hover,
  &:focus {
    .c-blog-post__meta {
      opacity: 1 !important;
    }
  }
}

.c-blog-post__meta {
  padding-top: $padding/4;
}


.c-blog-post:hover {
	.c-blog-post__image {
		opacity: 1;
		z-index: 1;
	}
}

body.is-touch {
  .c-blog-post__image {
  	display: none;
  }
}

.c-blog-post__image {
	display: block;
	position: absolute;
  mix-blend-mode: multiply;
	top: 0;
	left: 0;
	@include transform (translate(-50%,-50%));
	pointer-events: none;
	z-index: 1;
	opacity: 0;
	@include transition(opacity, 0.080s, ease);

	img {
		width: auto;
		max-width: 400px;
		max-height: 400px;
	}

}
