
//Column
.l-col-12 { @include col(12/12); }
.l-col-11 { @include col(11/12); }
.l-col-10 { @include col(10/12); }
.l-col-9 { @include col(9/12); }
.l-col-8 { @include col(8/12); }
.l-col-7 { @include col(7/12); }
.l-col-6 { @include col(6/12); }
.l-col-5 { @include col(5/12); }
.l-col-4 { @include col(4/12); }
.l-col-3 { @include col(3/12); }
.l-col-2 { @include col(2/12); }
.l-col-1 { @include col(1/12); }
//No-padding
.l-col-12--no-padding { @include col(12/12, false); }
.l-col-11--no-padding { @include col(11/12, false); }
.l-col-10--no-padding { @include col(10/12, false); }
.l-col-9--no-padding { @include col(9/12, false); }
.l-col-8--no-padding { @include col(8/12, false); }
.l-col-7--no-padding { @include col(7/12, false); }
.l-col-6--no-padding { @include col(6/12, false); }
.l-col-5--no-padding { @include col(5/12, false); }
.l-col-4--no-padding { @include col(4/12, false); }
.l-col-3--no-padding { @include col(3/12, false); }
.l-col-2--no-padding { @include col(2/12, false); }
.l-col-1--no-padding { @include col(1/12, false); }
//Push
.l-col-push-12 { @include push(12/12); }
.l-col-push-11 { @include push(11/12); }
.l-col-push-10 { @include push(10/12); }
.l-col-push-9 { @include push(9/12); }
.l-col-push-8 { @include push(8/12); }
.l-col-push-7 { @include push(7/12); }
.l-col-push-6 { @include push(6/12); }
.l-col-push-5 { @include push(5/12); }
.l-col-push-4 { @include push(4/12); }
.l-col-push-3 { @include push(3/12); }
.l-col-push-2 { @include push(2/12); }
.l-col-push-1 { @include push(1/12); }
.l-col-push-0 { @include push(0/12); }
//Pull
.l-col-pull-12 { @include pull(12/12); }
.l-col-pull-11 { @include pull(11/12); }
.l-col-pull-10 { @include pull(10/12); }
.l-col-pull-9 { @include pull(9/12); }
.l-col-pull-8 { @include pull(8/12); }
.l-col-pull-7 { @include pull(7/12); }
.l-col-pull-6 { @include pull(6/12); }
.l-col-pull-5 { @include pull(5/12); }
.l-col-pull-4 { @include pull(4/12); }
.l-col-pull-3 { @include pull(3/12); }
.l-col-pull-2 { @include pull(2/12); }
.l-col-pull-1 { @include pull(1/12); }
.l-col-pull-0 { @include pull(0/12); }
//Offset
.l-col-offset-12 { @include offset(12/12); }
.l-col-offset-11 { @include offset(11/12); }
.l-col-offset-10 { @include offset(10/12); }
.l-col-offset-9 { @include offset(9/12); }
.l-col-offset-8 { @include offset(8/12); }
.l-col-offset-7 { @include offset(7/12); }
.l-col-offset-6 { @include offset(6/12); }
.l-col-offset-5 { @include offset(5/12); }
.l-col-offset-4 { @include offset(4/12); }
.l-col-offset-3 { @include offset(3/12); }
.l-col-offset-2 { @include offset(2/12); }
.l-col-offset-1 { @include offset(1/12); }
.l-col-offset-0 { @include offset(0/12); }



@include media-query(large) {
	.l-col-lg-12 { @include col(12/12); }
	.l-col-lg-11 { @include col(11/12); }
	.l-col-lg-10 { @include col(10/12); }
	.l-col-lg-9 { @include col(9/12); }
	.l-col-lg-8 { @include col(8/12); }
	.l-col-lg-7 { @include col(7/12); }
	.l-col-lg-6 { @include col(6/12); }
	.l-col-lg-5 { @include col(5/12); }
	.l-col-lg-4 { @include col(4/12); }
	.l-col-lg-3 { @include col(3/12); }
	.l-col-lg-2 { @include col(2/12); }
	.l-col-lg-1 { @include col(1/12); }
	.l-col-lg-12--no-padding { @include col(12/12, false); }
	.l-col-lg-11--no-padding { @include col(11/12, false); }
	.l-col-lg-10--no-padding { @include col(10/12, false); }
	.l-col-lg-9--no-padding { @include col(9/12, false); }
	.l-col-lg-8--no-padding { @include col(8/12, false); }
	.l-col-lg-7--no-padding { @include col(7/12, false); }
	.l-col-lg-6--no-padding { @include col(6/12, false); }
	.l-col-lg-5--no-padding { @include col(5/12, false); }
	.l-col-lg-4--no-padding { @include col(4/12, false); }
	.l-col-lg-3--no-padding { @include col(3/12, false); }
	.l-col-lg-2--no-padding { @include col(2/12, false); }
	.l-col-lg-1--no-padding { @include col(1/12, false); }
	//Push
	.l-col-lg-push-12 { @include push(12/12); }
	.l-col-lg-push-11 { @include push(11/12); }
	.l-col-lg-push-10 { @include push(10/12); }
	.l-col-lg-push-9 { @include push(9/12); }
	.l-col-lg-push-8 { @include push(8/12); }
	.l-col-lg-push-7 { @include push(7/12); }
	.l-col-lg-push-6 { @include push(6/12); }
	.l-col-lg-push-5 { @include push(5/12); }
	.l-col-lg-push-4 { @include push(4/12); }
	.l-col-lg-push-3 { @include push(3/12); }
	.l-col-lg-push-2 { @include push(2/12); }
	.l-col-lg-push-1 { @include push(1/12); }
	.l-col-lg-push-0 { @include push(0/12); }
	//Pull
	.l-col-lg-pull-12 { @include pull(12/12); }
	.l-col-lg-pull-11 { @include pull(11/12); }
	.l-col-lg-pull-10 { @include pull(10/12); }
	.l-col-lg-pull-9 { @include pull(9/12); }
	.l-col-lg-pull-8 { @include pull(8/12); }
	.l-col-lg-pull-7 { @include pull(7/12); }
	.l-col-lg-pull-6 { @include pull(6/12); }
	.l-col-lg-pull-5 { @include pull(5/12); }
	.l-col-lg-pull-4 { @include pull(4/12); }
	.l-col-lg-pull-3 { @include pull(3/12); }
	.l-col-lg-pull-2 { @include pull(2/12); }
	.l-col-lg-pull-1 { @include pull(1/12); }
	.l-col-lg-pull-0 { @include pull(0/12); }
	//Offset
	.l-col-lg-offset-12 { @include offset(12/12); }
	.l-col-lg-offset-11 { @include offset(11/12); }
	.l-col-lg-offset-10 { @include offset(10/12); }
	.l-col-lg-offset-9 { @include offset(9/12); }
	.l-col-lg-offset-8 { @include offset(8/12); }
	.l-col-lg-offset-7 { @include offset(7/12); }
	.l-col-lg-offset-6 { @include offset(6/12); }
	.l-col-lg-offset-5 { @include offset(5/12); }
	.l-col-lg-offset-4 { @include offset(4/12); }
	.l-col-lg-offset-3 { @include offset(3/12); }
	.l-col-lg-offset-2 { @include offset(2/12); }
	.l-col-lg-offset-1 { @include offset(1/12); }
	.l-col-lg-offset-0 { @include offset(0/12); }
}

@include media-query(med-large) {
	.l-col-mdlg-12 { @include col(12/12); }
	.l-col-mdlg-11 { @include col(11/12); }
	.l-col-mdlg-10 { @include col(10/12); }
	.l-col-mdlg-9 { @include col(9/12); }
	.l-col-mdlg-8 { @include col(8/12); }
	.l-col-mdlg-7 { @include col(7/12); }
	.l-col-mdlg-6 { @include col(6/12); }
	.l-col-mdlg-5 { @include col(5/12); }
	.l-col-mdlg-4 { @include col(4/12); }
	.l-col-mdlg-3 { @include col(3/12); }
	.l-col-mdlg-2 { @include col(2/12); }
	.l-col-mdlg-1 { @include col(1/12); }
	.l-col-mdlg-12--no-padding { @include col(12/12, false); }
	.l-col-mdlg-11--no-padding { @include col(11/12, false); }
	.l-col-mdlg-10--no-padding { @include col(10/12, false); }
	.l-col-mdlg-9--no-padding { @include col(9/12, false); }
	.l-col-mdlg-8--no-padding { @include col(8/12, false); }
	.l-col-mdlg-7--no-padding { @include col(7/12, false); }
	.l-col-mdlg-6--no-padding { @include col(6/12, false); }
	.l-col-mdlg-5--no-padding { @include col(5/12, false); }
	.l-col-mdlg-4--no-padding { @include col(4/12, false); }
	.l-col-mdlg-3--no-padding { @include col(3/12, false); }
	.l-col-mdlg-2--no-padding { @include col(2/12, false); }
	.l-col-mdlg-1--no-padding { @include col(1/12, false); }
	//Push
	.l-col-mdlg-push-12 { @include push(12/12); }
	.l-col-mdlg-push-11 { @include push(11/12); }
	.l-col-mdlg-push-10 { @include push(10/12); }
	.l-col-mdlg-push-9 { @include push(9/12); }
	.l-col-mdlg-push-8 { @include push(8/12); }
	.l-col-mdlg-push-7 { @include push(7/12); }
	.l-col-mdlg-push-6 { @include push(6/12); }
	.l-col-mdlg-push-5 { @include push(5/12); }
	.l-col-mdlg-push-4 { @include push(4/12); }
	.l-col-mdlg-push-3 { @include push(3/12); }
	.l-col-mdlg-push-2 { @include push(2/12); }
	.l-col-mdlg-push-1 { @include push(1/12); }
	.l-col-mdlg-push-0 { @include push(0/12); }
	//Pull
	.l-col-mdlg-pull-12 { @include pull(12/12); }
	.l-col-mdlg-pull-11 { @include pull(11/12); }
	.l-col-mdlg-pull-10 { @include pull(10/12); }
	.l-col-mdlg-pull-9 { @include pull(9/12); }
	.l-col-mdlg-pull-8 { @include pull(8/12); }
	.l-col-mdlg-pull-7 { @include pull(7/12); }
	.l-col-mdlg-pull-6 { @include pull(6/12); }
	.l-col-mdlg-pull-5 { @include pull(5/12); }
	.l-col-mdlg-pull-4 { @include pull(4/12); }
	.l-col-mdlg-pull-3 { @include pull(3/12); }
	.l-col-mdlg-pull-2 { @include pull(2/12); }
	.l-col-mdlg-pull-1 { @include pull(1/12); }
	.l-col-mdlg-pull-0 { @include pull(0/12); }
	//Offset
	.l-col-mdlg-offset-12 { @include offset(12/12); }
	.l-col-mdlg-offset-11 { @include offset(11/12); }
	.l-col-mdlg-offset-10 { @include offset(10/12); }
	.l-col-mdlg-offset-9 { @include offset(9/12); }
	.l-col-mdlg-offset-8 { @include offset(8/12); }
	.l-col-mdlg-offset-7 { @include offset(7/12); }
	.l-col-mdlg-offset-6 { @include offset(6/12); }
	.l-col-mdlg-offset-5 { @include offset(5/12); }
	.l-col-mdlg-offset-4 { @include offset(4/12); }
	.l-col-mdlg-offset-3 { @include offset(3/12); }
	.l-col-mdlg-offset-2 { @include offset(2/12); }
	.l-col-mdlg-offset-1 { @include offset(1/12); }
	.l-col-mdlg-offset-0 { @include offset(0/12); }
}


@include media-query(med) {
	.l-col-md-12 { @include col(12/12); }
	.l-col-md-11 { @include col(11/12); }
	.l-col-md-10 { @include col(10/12); }
	.l-col-md-9 { @include col(9/12); }
	.l-col-md-8 { @include col(8/12); }
	.l-col-md-7 { @include col(7/12); }
	.l-col-md-6 { @include col(6/12); }
	.l-col-md-5 { @include col(5/12); }
	.l-col-md-4 { @include col(4/12); }
	.l-col-md-3 { @include col(3/12); }
	.l-col-md-2 { @include col(2/12); }
	.l-col-md-1 { @include col(1/12); }
	.l-col-md-12--no-padding { @include col(12/12, false); }
	.l-col-md-11--no-padding { @include col(11/12, false); }
	.l-col-md-10--no-padding { @include col(10/12, false); }
	.l-col-md-9--no-padding { @include col(9/12, false); }
	.l-col-md-8--no-padding { @include col(8/12, false); }
	.l-col-md-7--no-padding { @include col(7/12, false); }
	.l-col-md-6--no-padding { @include col(6/12, false); }
	.l-col-md-5--no-padding { @include col(5/12, false); }
	.l-col-md-4--no-padding { @include col(4/12, false); }
	.l-col-md-3--no-padding { @include col(3/12, false); }
	.l-col-md-2--no-padding { @include col(2/12, false); }
	.l-col-md-1--no-padding { @include col(1/12, false); }
	//Push
	.l-col-md-push-12 { @include push(12/12); }
	.l-col-md-push-11 { @include push(11/12); }
	.l-col-md-push-10 { @include push(10/12); }
	.l-col-md-push-9 { @include push(9/12); }
	.l-col-md-push-8 { @include push(8/12); }
	.l-col-md-push-7 { @include push(7/12); }
	.l-col-md-push-6 { @include push(6/12); }
	.l-col-md-push-5 { @include push(5/12); }
	.l-col-md-push-4 { @include push(4/12); }
	.l-col-md-push-3 { @include push(3/12); }
	.l-col-md-push-2 { @include push(2/12); }
	.l-col-md-push-1 { @include push(1/12); }
	.l-col-md-push-0 { @include push(0/12); }
	//Pull
	.l-col-md-pull-12 { @include pull(12/12); }
	.l-col-md-pull-11 { @include pull(11/12); }
	.l-col-md-pull-10 { @include pull(10/12); }
	.l-col-md-pull-9 { @include pull(9/12); }
	.l-col-md-pull-8 { @include pull(8/12); }
	.l-col-md-pull-7 { @include pull(7/12); }
	.l-col-md-pull-6 { @include pull(6/12); }
	.l-col-md-pull-5 { @include pull(5/12); }
	.l-col-md-pull-4 { @include pull(4/12); }
	.l-col-md-pull-3 { @include pull(3/12); }
	.l-col-md-pull-2 { @include pull(2/12); }
	.l-col-md-pull-1 { @include pull(1/12); }
	.l-col-md-pull-0 { @include pull(0/12); }
	//Offset
	.l-col-md-offset-12 { @include offset(12/12); }
	.l-col-md-offset-11 { @include offset(11/12); }
	.l-col-md-offset-10 { @include offset(10/12); }
	.l-col-md-offset-9 { @include offset(9/12); }
	.l-col-md-offset-8 { @include offset(8/12); }
	.l-col-md-offset-7 { @include offset(7/12); }
	.l-col-md-offset-6 { @include offset(6/12); }
	.l-col-md-offset-5 { @include offset(5/12); }
	.l-col-md-offset-4 { @include offset(4/12); }
	.l-col-md-offset-3 { @include offset(3/12); }
	.l-col-md-offset-2 { @include offset(2/12); }
	.l-col-md-offset-1 { @include offset(1/12); }
	.l-col-md-offset-0 { @include offset(0/12); }
}

@include media-query(med-sm) {
	.l-col-mdsm-12 { @include col(12/12); }
	.l-col-mdsm-11 { @include col(11/12); }
	.l-col-mdsm-10 { @include col(10/12); }
	.l-col-mdsm-9 { @include col(9/12); }
	.l-col-mdsm-8 { @include col(8/12); }
	.l-col-mdsm-7 { @include col(7/12); }
	.l-col-mdsm-6 { @include col(6/12); }
	.l-col-mdsm-5 { @include col(5/12); }
	.l-col-mdsm-4 { @include col(4/12); }
	.l-col-mdsm-3 { @include col(3/12); }
	.l-col-mdsm-2 { @include col(2/12); }
	.l-col-mdsm-1 { @include col(1/12); }
	.l-col-mdsm-12--no-padding { @include col(12/12, false); }
	.l-col-mdsm-11--no-padding { @include col(11/12, false); }
	.l-col-mdsm-10--no-padding { @include col(10/12, false); }
	.l-col-mdsm-9--no-padding { @include col(9/12, false); }
	.l-col-mdsm-8--no-padding { @include col(8/12, false); }
	.l-col-mdsm-7--no-padding { @include col(7/12, false); }
	.l-col-mdsm-6--no-padding { @include col(6/12, false); }
	.l-col-mdsm-5--no-padding { @include col(5/12, false); }
	.l-col-mdsm-4--no-padding { @include col(4/12, false); }
	.l-col-mdsm-3--no-padding { @include col(3/12, false); }
	.l-col-mdsm-2--no-padding { @include col(2/12, false); }
	.l-col-mdsm-1--no-padding { @include col(1/12, false); }
	//Push
	.l-col-mdsm-push-12 { @include push(12/12); }
	.l-col-mdsm-push-11 { @include push(11/12); }
	.l-col-mdsm-push-10 { @include push(10/12); }
	.l-col-mdsm-push-9 { @include push(9/12); }
	.l-col-mdsm-push-8 { @include push(8/12); }
	.l-col-mdsm-push-7 { @include push(7/12); }
	.l-col-mdsm-push-6 { @include push(6/12); }
	.l-col-mdsm-push-5 { @include push(5/12); }
	.l-col-mdsm-push-4 { @include push(4/12); }
	.l-col-mdsm-push-3 { @include push(3/12); }
	.l-col-mdsm-push-2 { @include push(2/12); }
	.l-col-mdsm-push-1 { @include push(1/12); }
	.l-col-mdsm-push-0 { @include push(0/12); }
	//Pull
	.l-col-mdsm-pull-12 { @include pull(12/12); }
	.l-col-mdsm-pull-11 { @include pull(11/12); }
	.l-col-mdsm-pull-10 { @include pull(10/12); }
	.l-col-mdsm-pull-9 { @include pull(9/12); }
	.l-col-mdsm-pull-8 { @include pull(8/12); }
	.l-col-mdsm-pull-7 { @include pull(7/12); }
	.l-col-mdsm-pull-6 { @include pull(6/12); }
	.l-col-mdsm-pull-5 { @include pull(5/12); }
	.l-col-mdsm-pull-4 { @include pull(4/12); }
	.l-col-mdsm-pull-3 { @include pull(3/12); }
	.l-col-mdsm-pull-2 { @include pull(2/12); }
	.l-col-mdsm-pull-1 { @include pull(1/12); }
	.l-col-mdsm-pull-0 { @include pull(0/12); }
	//Offset
	.l-col-mdsm-offset-12 { @include offset(12/12); }
	.l-col-mdsm-offset-11 { @include offset(11/12); }
	.l-col-mdsm-offset-10 { @include offset(10/12); }
	.l-col-mdsm-offset-9 { @include offset(9/12); }
	.l-col-mdsm-offset-8 { @include offset(8/12); }
	.l-col-mdsm-offset-7 { @include offset(7/12); }
	.l-col-mdsm-offset-6 { @include offset(6/12); }
	.l-col-mdsm-offset-5 { @include offset(5/12); }
	.l-col-mdsm-offset-4 { @include offset(4/12); }
	.l-col-mdsm-offset-3 { @include offset(3/12); }
	.l-col-mdsm-offset-2 { @include offset(2/12); }
	.l-col-mdsm-offset-1 { @include offset(1/12); }
	.l-col-mdsm-offset-0 { @include offset(0/12); }
}

@include media-query(sm) {
	.l-col-sm-12 { @include col(12/12); }
	.l-col-sm-11 { @include col(11/12); }
	.l-col-sm-10 { @include col(10/12); }
	.l-col-sm-9 { @include col(9/12); }
	.l-col-sm-8 { @include col(8/12); }
	.l-col-sm-7 { @include col(7/12); }
	.l-col-sm-6 { @include col(6/12); }
	.l-col-sm-5 { @include col(5/12); }
	.l-col-sm-4 { @include col(4/12); }
	.l-col-sm-3 { @include col(3/12); }
	.l-col-sm-2 { @include col(2/12); }
	.l-col-sm-1 { @include col(1/12); }
	.l-col-sm-12--no-padding { @include col(12/12, false); }
	.l-col-sm-11--no-padding { @include col(11/12, false); }
	.l-col-sm-10--no-padding { @include col(10/12, false); }
	.l-col-sm-9--no-padding { @include col(9/12, false); }
	.l-col-sm-8--no-padding { @include col(8/12, false); }
	.l-col-sm-7--no-padding { @include col(7/12, false); }
	.l-col-sm-6--no-padding { @include col(6/12, false); }
	.l-col-sm-5--no-padding { @include col(5/12, false); }
	.l-col-sm-4--no-padding { @include col(4/12, false); }
	.l-col-sm-3--no-padding { @include col(3/12, false); }
	.l-col-sm-2--no-padding { @include col(2/12, false); }
	.l-col-sm-1--no-padding { @include col(1/12, false); }
	//Push
	.l-col-sm-push-12 { @include push(12/12); }
	.l-col-sm-push-11 { @include push(11/12); }
	.l-col-sm-push-10 { @include push(10/12); }
	.l-col-sm-push-9 { @include push(9/12); }
	.l-col-sm-push-8 { @include push(8/12); }
	.l-col-sm-push-7 { @include push(7/12); }
	.l-col-sm-push-6 { @include push(6/12); }
	.l-col-sm-push-5 { @include push(5/12); }
	.l-col-sm-push-4 { @include push(4/12); }
	.l-col-sm-push-3 { @include push(3/12); }
	.l-col-sm-push-2 { @include push(2/12); }
	.l-col-sm-push-1 { @include push(1/12); }
	.l-col-sm-push-0 { @include push(0/12); }
	//Pull
	.l-col-sm-pull-12 { @include pull(12/12); }
	.l-col-sm-pull-11 { @include pull(11/12); }
	.l-col-sm-pull-10 { @include pull(10/12); }
	.l-col-sm-pull-9 { @include pull(9/12); }
	.l-col-sm-pull-8 { @include pull(8/12); }
	.l-col-sm-pull-7 { @include pull(7/12); }
	.l-col-sm-pull-6 { @include pull(6/12); }
	.l-col-sm-pull-5 { @include pull(5/12); }
	.l-col-sm-pull-4 { @include pull(4/12); }
	.l-col-sm-pull-3 { @include pull(3/12); }
	.l-col-sm-pull-2 { @include pull(2/12); }
	.l-col-sm-pull-1 { @include pull(1/12); }
	.l-col-sm-pull-0 { @include pull(0/12); }
	//Offset
	.l-col-sm-offset-12 { @include offset(12/12); }
	.l-col-sm-offset-11 { @include offset(11/12); }
	.l-col-sm-offset-10 { @include offset(10/12); }
	.l-col-sm-offset-9 { @include offset(9/12); }
	.l-col-sm-offset-8 { @include offset(8/12); }
	.l-col-sm-offset-7 { @include offset(7/12); }
	.l-col-sm-offset-6 { @include offset(6/12); }
	.l-col-sm-offset-5 { @include offset(5/12); }
	.l-col-sm-offset-4 { @include offset(4/12); }
	.l-col-sm-offset-3 { @include offset(3/12); }
	.l-col-sm-offset-2 { @include offset(2/12); }
	.l-col-sm-offset-1 { @include offset(1/12); }
	.l-col-sm-offset-0 { @include offset(0/12); }
}
