.o-slide {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}

.o-slide__video {
  // object-fit: contain;
  position: relative;
  width: auto;
}


.o-slide--text .o-slide__inner {
  overflow-y: scroll;
}

.o-slide__text__title {
  margin-top:33.33vh;
  margin-bottom: 1em;
}

.o-slide__inner {
  box-sizing: border-box;
  position: absolute; //This was previously set at relative
  top: 50%;
  left: 50%;
  @include transform(translate(-50%,-50%));
  width: 100%; //This is overwritten by data attributes
  height: 100%;   //This is overwritten by data attributes
  user-select: none;
}


.o-slide--audio__button {
  user-select: none;
  display:inline-block;
  width:auto;
  padding: $padding/4;
  &:hover {
    background-color: $white;
  }
}

.o-slide__image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: contain;';

  &[data-objectfit="contain"] {
    object-fit: contain !important;
    font-family: 'object-fit: contain;';
  }

  &[data-objectfit="cover"] {
    object-fit: cover !important;
    font-family: 'object-fit: cover;';
  }

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

}

.o-slide__video {
  width: 100%;
  height: 100%;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

}

//Sizing (Padding)
[data-size="full"] {
  padding: 0;
  //width: 100%;
  //height: 100%;
}

[data-size="large"] {
  padding: $padding*1;
  @include media-query(sm)  {
    padding: $padding*2;
    &[data-width="half"] {
      padding: $padding*2;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*1}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*1}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*2}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*2}/2);
    }
  }
}

[data-size="medium"] {
  padding: $padding*1;
  @include media-query(sm)  {
    padding: $padding*2.5;
    &[data-width="half"] {
      padding: $padding*1.25;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*1.25}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*1.25}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*2.5}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*2.5}/2);
    }
  }
  @include media-query(med-sm)  {
    padding: $padding*3;
    &[data-width="half"] {
      padding: $padding*1.5;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*1.5}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*1.5}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*3}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*3}/2);
    }
  }
  @include media-query(med)  {
    padding: $padding*3.5;
    &[data-width="half"] {
      padding: $padding*1.5;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*1.75}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*1.75}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*3.5}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*3.5}/2);
    }
  }
}

[data-size="small"] {
  padding: $padding*1;
  @include media-query(sm)  {
    padding: $padding*3.5;
    &[data-width="half"] {
      padding: $padding*1.75;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*1.75}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*1.75}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*3.5}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*3.5}/2);
    }
  }
  @include media-query(med-sm)  {
    padding: $padding*5;
    &[data-width="half"] {
      padding: $padding*2.5;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*2.5}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*2.5}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*5}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*5}/2);
    }
  }
  @include media-query(med)  {
    padding: $padding*6;
    &[data-width="half"] {
      padding: $padding*3;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*3}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*3}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*6}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*6}/2);
    }
  }
}

//Width of container
[data-width="full"] {
  width: 100% !important;
}

[data-width="half"] {
  width: 100% !important;
  @include media-query(sm)  {
    width: 50% !important;
  }
}

//Position of 'inner' container
[data-leftright="left"] {
  @include media-query(sm)  {
      left:0;
      @include transform(translate(0,-50%));
  }
}

[data-leftright="right"] {

  @include media-query(sm)  {
    right: 0;
    @include transform(translate(0,-50%));
  }
}

//Position of image within container
@include media-query(sm)  {
  [data-position="lefthalf_left"],
  [data-position="righthalf_left"] {
    object-position: 0 50%;
    &[data-objectfit="contain"] {
      font-family: 'object-fit: contain; object-position: 0 50%;';
    }

    &[data-objectfit="cover"] {
      object-fit: cover !important;
      font-family: 'object-fit: cover; object-position: 0 50%;';
    }
  }
}

@include media-query(sm)  {
  [data-position="lefthalf_right"],
  [data-position="righthalf_right"] {
    object-position: 100% 50%;
    &[data-objectfit="contain"] {
      font-family: 'object-fit: contain; object-position: 100% 50%;';
    }

    &[data-objectfit="cover"] {
      object-fit: cover !important;
      font-family: 'object-fit: cover; object-position: 100% 50%;';
    }
  }

}


/*
lefthalf_left: Left
lefthalf_center: Centered within left column
lefthalf_right: Right within left column
center: Center
righthalf_left: Left within right column
righthalf_center: Centered within right column
righthalf_right: Right
*/
