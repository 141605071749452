// Typography
// Typographical base selectors. Define base styles on single element selectors here.

/* =========================================================================  */
/* Base typography */
/* =========================================================================  */

html {
    color: $fake-black;
    font-family: $main-font;
    @include font($main-font-map-key);
}

body {
    @include font($main-font-map-key);
    @include font-settings--global();
}

::-moz-selection {
    //color: $white;
    background-color: rgba($primary-color, 0.3);
}
::selection {
    //color: $white;
    background-color: rgba($primary-color, 0.3);
}

a {
  color: inherit;
  text-decoration: none;
  @include transition(opacity, $speed, $ease);
}

.o-text a,
p a,
.t-link {
  color: inherit;
  text-decoration: none;
  @include transition(opacity, $speed, $ease);
  &:hover {
    color: inherit;
    opacity: 0.4;
    text-decoration: none;
  }
}

p {
  margin-bottom: $baseline;
  max-width: $max-text-width;
  &:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  font-size: inherit;
}

sup {
  top: -0.75em;
  font-size: 50%;
}

b, strong {
  font-weight: normal;
}

strong {
  text-decoration: underline;
}

em, italic {
  font-weight: normal;
  font-family: $italic;
}

.t-num {
    @include font-settings--tnum();
}

.t-small {
    @include font('small');
}

.t-medium {
    @include font('medium');
}

.t-large {
    @include font('large');
}

.t-uppercase {
  text-transform: uppercase;
}

.t-tag:after {
  content: ", ";
}
.t-tag:last-child:after {
  content: "";
}
