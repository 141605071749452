.c-curriculum {
	position: relative;
	width: 100%;
	@include media-query(med-sm) {
		position:absolute;
		//top: 18em; //The calculated top should be equal to the header height including padding
		left: 0;
		bottom: 0;
		width: 33.33%;
  }
}
