.o-item {
	// @include parent-behaviour();
  display:inline-block;
  padding: $padding/2.25;
  &:first-child{
    padding-left: 0px;
  }
  &:last-child{
    padding-right: $padding/1.125;
  }
  &:hover {
    .o-item__image,
    .o-item__thumbnail {
      opacity: 0.66;
    }
  }
}

//
// .o-item__ratio-box {
//   position: relative;
// 	height: 16.5em;
// 	display: block;
// 	width: auto;
//   padding-bottom:150%;
// }
//
// .o-item__ratio-box img,
// .o-item__ratio-box iframe,
// .o-item__ratio-box video {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	display: block;
// }

.o-item__image,
.o-item__thumbnail {
  @include transition(opacity, $speed, $ease);
}

.o-item__image {
	display: block;
	height: 16.5em;
	width: auto;
	img {
		height:100%;
	}
}

.o-item__image-bg{
	background-size: cover;
  background-position: center center;
  background-repeat: none;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  img {
    display: none;
  }
}

.o-item__image--fixed-ratio {
  position: relative;
  display: block;
	height: 16.5em;
	width: calc(16.5em * 1.5);
  overflow:hidden;
  // background-color: #efefef;

  &.landscape {
	  width: calc(16.5em * 1.5);
  }

  &.portrait {
  	width: calc(16.5em / 1.33);
  }

  &.square {
  	width: calc(16.5em);
  }

  img {
    object-fit: cover;
    object-position: center;
    height:auto;
    // max-width:auto !important;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
  	// height:100%;
  }
}

// .o-item__image--with-fixed-ratio {
// 	background-color: $gray;
// 	overflow: hidden;
//   position: relative;
//   height: 100%;
//   width:
// }
//
// .o-item__image--with-fixed-ratio img {
// 	position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// 	height:100%;
// }

//
// .o-item__image--is-landscape {
//   position: relative;
//   &:before {
//     display: block;
//     content: "";
//     width: 100%;
//     padding-top: (2 / 3) * 100%;
//   }
// }

.o-item__thumbnail {
  display: block;
  background-color: $gray-3;
	position: relative;
	height:16.5em;
	width:calc(16.5em / 1);

	span {
		@include child-overlay-behaviour();
		padding: $padding/3;
	}

}

.o-item__content,
.o-item__thumbnail,
.o-item__thumbnail--text,
.o-item__thumbnail--audio {
	white-space: normal;
}

.o-item__thumbnail--text {
	overflow: hidden;
	background-color: transparent;
	white-space: normal;

	span {
		padding: 0;
	}

	// &:after {
	// 		content: '';
	// 		width: 100%;
	// 		height: 5em;
	// 		position: absolute;
	// 		bottom: 0;
	//   // background: -webkit-linear-gradient(rgba(255,255,255, 0), rgba(255,255,255, 1));
	//   // background: -o-linear-gradient(rgba(255,255,255, 0), rgba(255,255,255, 1));
	//   // background: linear-gradient(rgba(255,255,255, 0), rgba(255,255,255, 1));
	// }
}


.o-item__thumbnail--text span {
  @include transform(translateY(-0.225em));
}



.o-item__thumbnail--audio {
  overflow: hidden;
  background-color: transparent;
  white-space: normal;

  span svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%,-50%));
  }

}

.o-audio-icon__shape {
  fill: $text-color;
  @include transition(opacity, $speed, $ease);
}

.o-item__thumbnail--empty {
	&:before {
    display: block;
    content: "";
		height:16.5em;
		width:calc(16.5em / 1);
  }
}

.o-item__content {
	padding-top: $padding/4;
	padding-bottom: $padding/4;
}
