.c-index {
  //@include display();
}

.c-index__link {
  position: relative;
  //float:left;
  display:block;
  //padding-right: 0.33em;
  sup {
    margin: 0 0 0 5px;
  }
}

.c-index__list-item:not(:last-child) {
  .c-index__list-item__link{
    &:after {
      content: ', ';
    }
  }
}
