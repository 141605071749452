.c-project-moreinfo {
  opacity:0;
  pointer-events: none;
  @include transition(opacity, $slower, $ease);
  &.is-active {
    pointer-events: auto;
    cursor:auto;
    opacity: 1;
  }
}

.c-project-slide__content {
  opacity:0;
	@include transition(opacity, $slower, $ease);
	&.is-active {
		opacity: 1;
	}
}

.c-project-slide__header {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  @include media-query(sm) {
    width:85%;
  }
  .c-project-slide__header__breadcrumb {
    display:block;
    padding-left: $padding/1.5;
    padding-right: $padding/1.5;
    padding-top: $padding/1.5;
    padding-bottom: $padding/1.5;
    @include media-query(med-sm) {
      padding-left: $padding/1.5;
      padding-right: $padding/1.5;
      padding-top: $padding/3;
      padding-bottom: $padding/3;
    }
     a {
      display: inline;
      padding-top: $padding/3;
      padding-bottom: $padding/6;
      @include media-query(med-sm) {
        display: inline-block;
      }
     }
  }
}

.c-project-slide__title {
  position: fixed;
  bottom: 0;
  left: 0;
  width:66.66%;
  > * {
    display: inline-block;
    padding: $padding/1.5;
  }
}

.c-project-slide__counter {
  position: fixed;
  top: $padding/1.5;
  right: $padding/1.5;
  width:15%;
  text-align: right;
  display: none;
  @include media-query(sm) {
    display: block;
  }
}

.c-project-slide__more {
  position: fixed;
  bottom: 0;
  right: 0;
  width:33.33%;
  text-align: right;
  padding: $padding/3 $padding/3 $padding/3 $padding/3;
  > * {
    display: inline-block;
    padding: $padding/3 $padding/3 $padding/3 0;
    // padding: $padding/1.5;
  }
}

.c-project-slide__sound {
  position: fixed;
  bottom: 0;
  left: 50%;
  @include transform(translateX(-50%));
  width: auto;
  text-align: center;
  padding: $padding/3 $padding/3 $padding/3 $padding/3;
  > * {
    display: inline-block;
    padding: $padding/3 $padding/3 $padding/3 0;
    // padding: $padding/1.5;
  }
}



body.is-touch {
  .c-project-slide__arrow-prev,
  .c-project-slide__arrow-next {
    display: block;
  }
}

.c-project-slide__arrow-prev,
.c-project-slide__arrow-next {
  display: none;
  position: fixed;
  top:50%;
  user-select: none;
}

.c-project-slide__arrow-prev {
  left:0;
  span {
    display: inline-block;
    padding: $padding/1.5;
  }
}

.c-project-slide__arrow-next {
  right:0;
  span {
    display: inline-block;
    padding: $padding/1.5;
  }
}
