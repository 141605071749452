.o-title {
    margin-bottom: $baseline/2;
}

.o-title--large {
    @include font('large');
    margin-bottom: $baseline/2;
}

.o-title--small {
    @include font('small');
    margin-bottom: $baseline/2;
}

.o-title--body {
    @include font('body-sans');
    margin-bottom: $baseline/2;
}
