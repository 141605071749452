// Layout

/* =========================================================================  */
/* Variables layout */
/* =========================================================================  */

//$cols: 12;
//$cols_m: 12;

/* =========================================================================  */
/* Layout settings */
/* =========================================================================  */

$layouts-name:         ( 'main',   'sm',     'med-sm',   'med',      'med-large',    'large',    'xlarge'    );
$layouts-break-point:  ( 0,        520px,     768px,      1024px,     1280px,         1400px,    1800px       );

$max-container-width: 100%;
$max-text-width: 50em;

$baseline: 1.25em;
$padding: 1.95em;

/* =========================================================================  */
/* Layout mix-ins */
/* =========================================================================  */

@mixin container() {
  @include parent-behaviour;
  width: 100%;
  max-width: $max-container-width;
  margin-left: auto;
  margin-right: auto;
  padding-top: $padding/2;
  padding-bottom: $padding/2;
}

@mixin container-item() {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin section() {
  @include parent-behaviour;
}

@mixin section--padding() {
  @include parent-behaviour;
  padding-top: $padding/2;
  padding-bottom: $padding/2;
}

@mixin section--padding--less() {
  @include parent-behaviour;
  padding-top: $padding/4;
  padding-bottom: $padding/4;
}

@mixin section--padding--more() {
  @include parent-behaviour;
  padding-top: $padding*2;
  padding-bottom: $padding*2;
}

@mixin col--padding-bottom() {
  @include parent-behaviour;
  padding-bottom: $padding*2;
}

@mixin row($inside: false) {
  @if($inside == true) {
    margin-left: -$padding/2;
    margin-right: -$padding/2;
  }
  @else {
    @include parent-behaviour;
    width: 100%;
    padding-left: $padding/2;
    padding-right: $padding/2;
  }
  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin row-no-padding() {
  @include parent-behaviour;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin col($width: 1, $paddingBool: true, $centered: false) {
  @if($centered == true) {
    margin-left: auto;
    margin-right: auto;
    display:block;
  }
  @else {
    float: left;
  }
  width: percentage($width);
  @if($paddingBool == true) {
    padding-left: $padding/2;
    padding-right: $padding/2;
  }
}

@mixin push($width: 0) {
  left: percentage($width);
}

@mixin pull($width: 0) {
  right: percentage($width);
}

@mixin offset($width: 0) {
  margin-left: percentage($width);
}

/*
Paddings
*/

@mixin padding() {
  padding: $padding/2;
}

@mixin padding--left-right() {
  padding-left: $padding/2;
  padding-right: $padding/2;
}

@mixin padding--left() {
  padding-left: $padding/2;
}

@mixin padding--right() {
  padding-right: $padding/2;
}

@mixin padding--top-bottom() {
  padding-top: $padding/2;
  padding-bottom: $padding/2;
}

@mixin padding--bottom() {
  padding-bottom: $padding/2;
}

@mixin padding--top() {
  padding-top: $padding/2;
}
