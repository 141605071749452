body.page-is-project {
	.c-site-header {
		display: none;
	}
}

body.page-is-article {
	.c-site-header {
		display: none;
	}
}

// 
// .c-site-header-back a{
// 	color: inherit;
// 	&:before {
// 		content: '→';
// 		opacity: 0;
// 		position: absolute;
// 		left:0;
// 		@include transition(opacity, $speed, ease);
// 	}
// }

.c-site-header,
.c-site-header-back {
	// mix-blend-mode: difference;
	position:fixed;
	left: 0;
	top: 0;
	color: inherit;
	z-index: $z-header;
	padding-left: $padding;
	padding-right: $padding;
	padding-top: $padding/1.35;
	width: auto;
	display:block;
	white-space: nowrap;
	opacity: 1;
	user-select: none;

	@include transition(opacity, $speed, $ease);

	&.is-hidden{
	 opacity: 0;
	 pointer-events:none;
	}

}

.show-on-homepage {
	display:none;
}
.show-on-other-pages {
	display:block;
}

body.page-is-projects {
	.show-on-homepage {
		display:block;
	}
	.show-on-other-pages {
		display:none;
	}
}

.c-site-header.has-scrolled-state {

	@include transition(all, $speed, $ease);
	opacity: 0;
	pointer-events:none;

	// .c-navigation {
	// 	// @include transition(all, $speed, $ease);
	// 	// opacity: 0;
	// 	// @include transform(translateY(-5px));
	// 	ul.hide-on-hover {
	// 		@include transition(all, $speed, $ease);
	// 		opacity: 0;
	// 		//@include transform(translateY(-5px));
	// 	}
	// }
}

.c-site-header.has-scrolled-state:hover {
	// opacity: 1;
	// @include transform(translateY(0));
	// .c-navigation {
	// 	opacity: 1;
	// 	@include transform(translateY(0));
	// }
}

.c-site-header a {
	color: inherit;
	&:hover {
		opacity: 1;
	}
}

// .c-site-header__subtitle {
// 	opacity:1;
// }

.c-navigation {
	position: relative;
}

.c-navigation__list {
	position: absolute;
}


.c-navigation {
	ul.show-on-hover {
		opacity: 0;
	}
	ul.hide-on-hover {
		opacity: 1;
	}
}

.c-site-header.is-toggled {
	.c-navigation {
		ul.show-on-hover {
			opacity: 1;
		}
		ul.hide-on-hover {
			opacity: 0;
		}
	}
}

body:not(.is-touch) {
	.c-site-header:hover {
		.c-navigation {
			ul.show-on-hover {
				opacity: 1;
			}
			ul.hide-on-hover {
				opacity: 0;
			}
		}
	}
}

.c-site-header:hover,
.c-site-header.is-toggled {
	.c-navigation {
		ul.show-on-hover {
			opacity: 1;
		}
		ul.hide-on-hover {
			opacity: 0;
		}
	}
}

.c-navigation__list li .c-navigation__list-item__text-container {
	padding-left: 1.01em;
	display:block;
	color: inherit
}

.c-navigation__list li{
	color: inherit;

	&:before {
		content: '→';
		opacity: 0;
		position: absolute;
		left:0;
		@include transition(opacity, $speed, ease);
	}

	&:hover,
	&.is-active, {
		&:before {
			opacity: 1;
		}
	}
}
