//Container
//Acts like a wrapper/inner div

.l-container {
	@include container();
}

.l-container--page {
	@include container();
}

.l-container-item {
	@include container-item();
}
