.o-image,
.o-image img {
    display: inline-block;
    max-width: 100%;
}

//Fit image within container
.o-image-wrap {
	width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
	display: block;
}

.o-image-wrap--in-square {
	padding-bottom: 100%;
}

.o-image-wrap img {
	position: absolute;
	bottom: 0;
	left: 50%;
	bottom: 0;
	right: 0;
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	@include transform(translateX(-50%));
	zoom: 2;
}

//Image with background
.o-image--with-bg,
.o-image--with-fixed-ratio {
	background-color: $gray;
	overflow: hidden;
}

.o-image--with-bg img,
.o-image--with-fixed-ratio img {
	position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	height:100%;
}

.o-image--is-landscape {
	@include aspect-ratio(3,2);
}

.o-image--is-portrait {
	@include aspect-ratio(2,3);
}

.o-image--is-square {
	@include aspect-ratio(1,1);
}

//Captions
.o-caption,
figcaption {
	position: relative;
	display:block;
	height: auto;
	@include padding--top();
}

//Caption positioning
.o-caption--left,
figcaption.o-caption--left {
	float:left !important;
}

.o-caption--center,
figcaption.o-caption--center {
	float:none !important;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.o-caption--right,
figcaption.o-caption--right {
	float:right !important;
}
