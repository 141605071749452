/* =========================================================================  */
/* Font Stacks */
/* =========================================================================  */

/**
 * Fonts in use during FOUT.
 */
$serif-fallback: "Times New Roman", Times, Georgia, serif;
$sans-serif-fallback: "Helvetica Neue", Helvetica, Arial, sans-serif;

/**
 * Fonts in use after FOUT.
 */
$serif: 'Times New Roman', $serif-fallback;
$sans-serif: 'ModeratLight', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$italic:  'ModeratLightItalic', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;

/**
 * Main font detection.
 */
$main-font-type: 'sans-serif' !default;
$main-font-fallback: null;
$main-font: null;
$main-font-map-key: null;
@if $main-font-type == 'sans-serif' {
    $main-font-fallback: $sans-serif-fallback;
    $main-font: $sans-serif;
    $main-font-map-key: 'body-sans';
}
@else if $main-font-type == 'serif' {
    $main-font-fallback: $serif-fallback;
    $main-font: $serif;
    $main-font-map-key: 'body-serif';
}

$line-height: 1.25em;
/* =========================================================================  */
/* Font weights */
/* =========================================================================  */

$normal: 400;
$bold: 600;

/* =========================================================================  */
/* Font maps */
/* =========================================================================  */

$fonts: (
    'body-sans': (
        'font-size': (
            'main': 11px,
            'sm': 12px,
            'med': 14px,
            'large': 16px,
            'xlarge': 18px
        ),
        'line-height': (
            'main': $line-height,
            'sm': $line-height,
            'med': $line-height,
            'large': $line-height,
            'xlarge': $line-height
        )
    ),
    'small': (
        'font-size': (
            'main': 11px,
            'sm': 12px,
            'med': 13px,
            'large': 14px,
            'xlarge': 15px
        ),
        'line-height': (
            'main': $line-height,
            'sm': $line-height,
            'med': $line-height,
            'large': $line-height,
            'xlarge': $line-height
        )
    ),
    'medium': (
        'font-size': (
            'main': 20px,
            'sm': 20px,
            'med': 24px,
            'large': 28px,
            'xlarge': 32px
        ),
        'line-height': (
            'main': 1.225,
            'sm': 1.225,
            'med': 1.225,
            'large': 1.225,
            'xlarge': 1.225
        )
    ),
    'large': (
        'font-size': (
            'main': 32px,
            'sm': 32px,
            'med': 48px,
            'large': 64px,
            'xlarge': 72px
        ),
        'line-height': (
            'main': 1em,
            'sm': 1em,
            'med': 1em,
            'large': 1em,
            'xlarge': 1em
        ),
        'letter-spacing': (
            'main': -0.025em,
            'sm': -0.025em,
            'med': -0.025em,
            'large': -0.025em,
            'xlarge': -0.025em
        )
    )

);

/* =========================================================================  */
/* Font settings */
/* =========================================================================  */

@mixin font-settings--global() {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-settings--tnum() {
	-moz-font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
  -ms-font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
  -o-font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
  -webkit-font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
  font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
}
