// Fonts

@font-face {
    font-family: "ModeratLight";
    src: url("/assets/fonts/Moderat-Light.ttf");
    src: url("/assets/fonts/Moderat-Light.svg");
    src: url("/assets/fonts/Moderat-Light.eot");
    src: url("/assets/fonts/Moderat-Light.eot?#iefix") format("embedded-opentype"),
         url("/assets/fonts/Moderat-Light.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ModeratLightItalic";
    src: url("/assets/fonts/Moderat-LightItalic.ttf");
    src: url("/assets/fonts/Moderat-LightItalic.svg");
    src: url("/assets/fonts/Moderat-LightItalic.eot");
    src: url("/assets/fonts/Moderat-LightItalic.eot?#iefix") format("embedded-opentype"),
         url("/assets/fonts/Moderat-LightItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
