//Overlay
.o-overlay {

	@include overlay(100%, 100%, $z-overlay, $background-color);
	opacity: 0;
	@include transition(opacity, $speed, $ease);

	&.is-active {
		pointer-events: auto;
		opacity: 1;
		@include transition(opacity, $speed, $ease);
	}

}

.o-overlay-black {
	@include overlay(100%, 100%, $z-overlay, $black);
	opacity: 0;
	@include transition(opacity, $speed, $ease);

	&.is-active {
		pointer-events: auto;
		opacity: 1;
		@include transition(opacity, $speed, $ease);
	}
}

/*
.o-overlay--page {
	padding: $padding/2;
	@include media-query(sm)  {
	  padding: $padding/3;
	}
}
*/

.o-overlay__content {
	padding: $padding*2.5 $padding/2;

	width:100%;
	height:100%;
	overflow: scroll;
}
